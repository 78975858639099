import FrameComponent2 from "./frame-component2";
import PropTypes from "prop-types";

const Benefits = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch bg-[#edf4f3] overflow-hidden flex flex-col items-center justify-start py-[80px] px-[20px] box-border max-w-full text-center text-40px text-lightOnSurface font-sans mq750:gap-[30px] mq750:pt-[52px] mq750:pb-[52px] mq750:box-border ${className}`}
    >
      <div className="w-[1224px] flex flex-col items-center justify-start gap-[60px] max-w-full mq750:gap-[30px]">
        <h1 className="m-[0px] relative text-inherit leading-[52px] font-semibold font-[inherit] inline-block max-w-full mq450:text-[24px] mq450:leading-[31px] mq1050:text-[32px] mq1050:leading-[42px]">
          A perfect fit for everyone
        </h1>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[32px] max-w-full text-left text-[28px] mq750:gap-[16px]">
          <FrameComponent2
            brokers="Brokers"
            line3="/line3-1.svg"
            byStandardizingTheLanguage="By standardizing the language and terms through the clause library, brokers ensure consistency and efficiency across the team, all while enjoying the benefits of quick document turnaround times"
          />
          <FrameComponent2
            propWidth="97px"
            brokers="Agents"
            propMinWidth="97px"
            propDisplay="inline-block"
            line3="/line3-2.svg"
            byStandardizingTheLanguage="For agents, ReBillion transforms the contract creation process into a swift and seamless task. Agents can customize offers, agreements, and other critical documents with ease."
          />
          <FrameComponent2
            propWidth="330px"
            brokers="Transaction Coordinator"
            propMinWidth="unset"
            propDisplay="unset"
            line3="/line3-3.svg"
            byStandardizingTheLanguage="ReBillion helps Transaction Coordinators facilitate smoother transactions and contributes to overall team success, by ensuring every piece of information is accurately captured and properly formatted."
          />
        </div>
      </div>
    </section>
  );
};

Benefits.propTypes = {
  className: PropTypes.string,
};

export default Benefits;
