import io from 'socket.io-client';
import localStorageSlim from 'localstorage-slim';
import env from '../config/env';

// Connect to WebSocket server
const socket = io(env.socketUrl, { reconnection: true, transports: ['websocket'] });

// client-side
socket.on("connect", () => {
    //    console.log(`Socket connected successfully ${socket.id}`)
});

export const getCasdoorConfig = (setCasdoorConfig) => {

    let casdoorConfig = localStorageSlim.get('casdoorConfig', { decrypt: true });

    if (casdoorConfig) {
        setCasdoorConfig(JSON.parse(casdoorConfig))
    } else {
        // console.log(`Event Fired: getAhaanClientConfig`)
        socket.emit('getAhaanClientConfig', JSON.stringify({
            accountKey: env.orgName,
            webType: 'tcweb'
        }))

        socket.on("ahaanClientConfigDetails", (data) => {
            // console.log(`Event Received: authTokenDetails`)
            const parsedData = JSON.parse(data)
                // console.log('parsedData: ', parsedData)
                //  parsedData.redirectUri = "http://localhost:3000/callback"
            setCasdoorConfig(parsedData)

            localStorageSlim.set('casdoorConfig', JSON.stringify(parsedData), { encrypt: true, ttl: (2 * 24 * 60 * 60) });
        })

        socket.on("ahaanClientConfigErrorDetails", (data) => {
            // console.log(`Event Received: ahaanClientConfigErrorDetails`)
            const parsedData = JSON.parse(data)
                // console.log('parsedData: ', parsedData)
        })

        socket.on("disconnect", () => {
            // console.log('socket disconnected !!')
        });
    }

}


export const generateAuthToken = (casdoorConfig, casdoorUserObj, accessToken, setAuthToken) => {

    const accountId = casdoorConfig.accountId
    const employeeName = casdoorUserObj.preferred_username
    const applicationName = casdoorConfig.appName

    // console.log('casdoorUserObj: ',casdoorUserObj)

    localStorageSlim.set('casdoorConfig', JSON.stringify(casdoorConfig), { encrypt: true, ttl: (2 * 24 * 60 * 60) });

    localStorageSlim.set('accessToken', accessToken, { encrypt: true, ttl: (12 * 60 * 60) });
    localStorageSlim.set('casdoorUserObj', JSON.stringify(casdoorUserObj), { encrypt: true, ttl: (12 * 60 * 60) });
    localStorageSlim.set('accountId', accountId, { encrypt: true, ttl: (12 * 60 * 60) });

    // console.log(`Event Fired: generateAuthToken`)
    socket.emit('generateAuthToken', JSON.stringify({
        accountId: accountId,
        accessToken: accessToken,
        employeeName: employeeName,
        applicationName: applicationName
    }))

    socket.on("authTokenDetails", (data) => {
        // console.log(`Event Received: authTokenDetails`)
        const parsedData = JSON.parse(data)
            // console.log(parsedData)
        localStorageSlim.set('userId', parsedData.employeeId, { encrypt: true, ttl: (12 * 60 * 60) });
        localStorageSlim.set('token', parsedData.authToken, { encrypt: true, ttl: (12 * 60 * 60) });
        setAuthToken([parsedData.authToken])
            // console.log(localStorageSlim.get('token', { decrypt: true }))
    })

    socket.on("authTokenGenerateErr", (data) => {
        // console.log(`Event Received: authTokenGenerateErr`)
        const parsedData = JSON.parse(data)
        console.log(parsedData)
    })

}


// casdoor config from backend
// {
//     "clientId": "c298d9e811c29e4301b7",
//     "serverUrl": "https://ahaanauth.riogpt.xyz",
//     "organizationName": "org3",
//     "appName": "org3",
//     "redirectUri": "https://ahaanweb.riogpt.xyz/callback.html",
//     "callbackUrlScheme": "casdoor",
//     "accountId": "dXIXMnLLCt",
//     "accountName": "Org3",
//     "applicationUrl": "https://ahaan.riogpt.xyz",
//     "apiKey": "kkelSXjdJMHKAkzbIrEUwCIYdBNnlNQFOEusjBnd",
//     "redirectPath": "/callback",
//     "scope": "profile"
// }



// user obj of casdoor
// {
//     "sub": "140e5e7a-cf88-4455-bedb-452566037fcc",
//     "iss": "https://ahaanauth.riogpt.xyz",
//     "aud": "c298d9e811c29e4301b7",
//     "preferred_username": "org3",
//     "name": "org3",
//     "picture": "https://cdn.casbin.org/img/casbin.svg",
//     "roles": [
//         "org_admin"
//     ]
// }