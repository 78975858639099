import PropTypes from "prop-types";

const FrameComponent1 = ({
  className = "",
  supportingText,
  header,
  header1,
}) => {
  return (
    <div
      className={`flex-1 rounded-[6px] bg-[#fff] overflow-hidden flex flex-col items-start justify-start py-[20px] pl-[16px] pr-[12px] box-border gap-[16px] min-w-[216px] max-w-[288px] text-left text-[16px] text-lighOnSurfaceVariant font-sans ${className}`}
    >
      <div className="self-stretch relative leading-[24px]">
        {supportingText}
      </div>
      <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-center text-[#fef7ff] font-sans">
        <div className="h-[40px] w-[40px] relative overflow-hidden shrink-0">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[50%] max-w-full overflow-hidden max-h-full object-cover"
            loading="lazy"
            alt=""
            src="/background@2x.png"
          />
          <div className="absolute top-[calc(50%_-_20px)] left-[calc(50%_-_20px)] tracking-[0.15px] leading-[24px] font-medium hidden items-center justify-center w-full h-full">
            A
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start text-left text-lighOnSurfaceVariant font-sans">
          <div className="self-stretch relative leading-[24px]">{header}</div>
          <div className="self-stretch relative leading-[24px] font-medium text-lightOnSurface">
            {header1}
          </div>
          <div className="self-stretch relative text-[14px] tracking-[0.25px] leading-[20px] font-sans text-onSurface hidden">
            Subhead
          </div>
        </div>
      </div>
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
  supportingText: PropTypes.string,
  header: PropTypes.string,
  header1: PropTypes.string,
};

export default FrameComponent1;
