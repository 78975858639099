import PropTypes from "prop-types";

const HorizontalMiddleInset = ({ className = "" }) => {
  return (
    <div
      className={`h-0 w-full !m-[0] absolute right-[-387px] bottom-[39px] left-[387px] flex flex-col items-start justify-center max-w-full z-[1] ${className}`}
    >
      <div className="self-stretch h-0 relative" />
    </div>
  );
};

HorizontalMiddleInset.propTypes = {
  className: PropTypes.string,
};

export default HorizontalMiddleInset;
