import { useMemo } from "react";
import PropTypes from "prop-types";

const FrameComponent2 = ({
  className = "",
  propWidth,
  brokers,
  propMinWidth,
  propDisplay,
  line3,
  byStandardizingTheLanguage,
}) => {
  const audienceDetailsStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const brokersStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
      display: propDisplay,
    };
  }, [propMinWidth, propDisplay]);

  return (
    <div
      className={`flex-1 rounded-[8px] overflow-hidden flex flex-col items-start justify-start py-[16px] px-[0px] box-border min-w-[290px] max-w-full text-left text-[28px] text-lightOnSurface font-sans ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
        <div
          className="w-[105px] flex flex-col items-center justify-center"
          style={audienceDetailsStyle}
        >
          <h2
            className="m-[0px] relative text-inherit leading-[36px] font-semibold font-[inherit] inline-block min-w-[105px] mq450:text-[22px] mq450:leading-[29px]"
            style={brokersStyle}
          >
            {brokers}
          </h2>
          <img
            className="self-stretch h-[8px] relative rounded-[4px] max-w-full overflow-hidden shrink-0"
            alt=""
            src={line3}
          />
        </div>
        <div className="self-stretch relative text-[16px] leading-[24px] text-lighOnSurfaceVariant">
          {byStandardizingTheLanguage}
        </div>
      </div>
    </div>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
  brokers: PropTypes.string,
  line3: PropTypes.string,
  byStandardizingTheLanguage: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
  propMinWidth: PropTypes.any,
  propDisplay: PropTypes.any,
};

export default FrameComponent2;
