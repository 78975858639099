import FrameComponent1 from "./frame-component1";
import PropTypes from "prop-types";

const UserReviews = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch overflow-hidden flex flex-row items-start justify-start max-w-full text-left text-40px text-[#fff] font-sans ${className}`}
    >
      <div className="flex-1 overflow-hidden flex flex-row items-start justify-start bg-[url('/testimonial-container@3x.png')] bg-cover bg-no-repeat bg-[top] max-w-full">
        <div className="flex-1 bg-[rgba(0,0,0,0.6)] overflow-hidden flex flex-row items-start justify-center py-[80px] px-[20px] box-border max-w-full mq750:pt-[52px] mq750:pb-[52px] mq750:box-border">
          <div className="w-[1224px] flex flex-col items-center justify-start gap-[60px] max-w-full z-[1] mq750:gap-[30px]">
            <h1 className="m-[0px] relative text-inherit leading-[52px] font-semibold font-[inherit] inline-block max-w-full mq450:text-[24px] mq450:leading-[31px] mq1050:text-[32px] mq1050:leading-[42px]">
              Loved by real estate professionals
            </h1>
            <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[24px] text-[16px] text-lighOnSurfaceVariant">
              <FrameComponent1
                supportingText="As a transaction coordinator, speed is crucial. ReBillion’s buyer document generator is not only fast but also incredibly accurate. "
                header="Transactions Coordinator"
                header1="Olivia S."
              />
              <FrameComponent1
                supportingText="I’ve tried various tools, but ReBillion stands out for its efficiency and ease of use. My time now goes straight into growing my business."
                header="Independent Realtor"
                header1="Emma L."
              />
              <FrameComponent1
                supportingText="I can create precise, professional documents in minutes, giving my clients confidence that they’re getting the best deal."
                header="Real Estate Broker"
                header1="James M."
              />
              <FrameComponent1
                supportingText="I really like the document summarizer. It cut my workload in half, I no longer dread those long contracts – the summarizer does all the heavy lifting!"
                header="Real Estate Agent"
                header1="Sarah T."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

UserReviews.propTypes = {
  className: PropTypes.string,
};

export default UserReviews;
