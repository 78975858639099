import Head from "next/head";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Sdk from "casdoor-js-sdk";
import { useGlobalContext } from "../context/GlobalContext";
import { getCasdoorConfig } from "../socket/socket.js";
import localStorageSlim from 'localstorage-slim';
import Desktop from "../components/landingPage/index.js";
import Loader from "../components/agreementGeneration/Loader.js";

const Home = () => {
  const { casdoorConfig, setCasdoorConfig } = useGlobalContext();
  const router = useRouter();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorageSlim.get('token');
    if (token) {
      router.push('/dashboard');
    } else {
      setLoading(false);
      getCasdoorConfig(setCasdoorConfig);
    }
  }, []);

  const login = () => {
    const CasdoorSDK = new Sdk(casdoorConfig);
    CasdoorSDK.signin_redirect();
  }

  if (loading) {
    return <Loader loadingMessage="Loading..." />;
  }

  return (
    <>
      <Head>
        <title>Welcome</title>
      </Head>
      <Desktop login={login} />
    </>
  );
}

export default Home;
