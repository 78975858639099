import FrameComponent from "./frame-component";
import PropTypes from "prop-types";

const Products = ({ className = "", login }) => {
  return (
    <section
      className={`self-stretch overflow-hidden flex flex-col items-center justify-start py-[80px] px-[20px] box-border gap-[60px] max-w-full text-center text-40px text-lightOnSurface font-sans lg:pt-[52px] lg:pb-[52px] lg:box-border mq450:pt-[22px] mq450:pb-[22px] mq450:box-border mq750:gap-[30px] mq1050:pt-[34px] mq1050:pb-[34px] mq1050:box-border ${className}`}
    >
      <h1 className="m-[0px] w-[840px] relative text-inherit leading-[52px] font-semibold font-[inherit] flex items-center max-w-full mq450:text-[24px] mq450:leading-[31px] mq1050:text-[32px] mq1050:leading-[42px]">
        <span className="w-full">
          <p className="m-[0px]">{`An integrated suite of tools for your `}</p>
          <p className="m-[0px]">real estate business</p>
        </span>
      </h1>
      <div className="w-[1224px] flex flex-col items-start justify-start gap-[80px] max-w-full text-left text-[14px] text-lighOnSurfaceVariant mq450:gap-[20px] mq750:gap-[40px]">
        <FrameComponent
          rEBILLIONSUMMARIZER="REBILLION SUMMARIZER"
          simplifyDocumentSummarization="Simplify Document Summarization"
          designedToSaveYouTimeAndReduc="Designed to save you time and reduce manual effort, our tool intelligently extracts key information from lengthy documents. Whether you're dealing with contracts, disclosures, or reports."
          cardSpacing="/document_summarizer.png"
          login={login}
        />
        <FrameComponent
          rEBILLIONSUMMARIZER="REBILLION AGREEMENT GENERATOR"
          simplifyDocumentSummarization="Create Accurate Offers"
          propHeight="unset"
          propDisplay="unset"
          designedToSaveYouTimeAndReduc="Write accurate and professional documents in minutes. Customize terms, conditions, and pricing with ease, ensuring every offer meets your clients' needs."
          cardSpacing="/offer_writing.png"
          login={login}
        />
        <FrameComponent
          rEBILLIONSUMMARIZER="BUYER DOCUMENT GENERATOR"
          simplifyDocumentSummarization="Streamline Buyer Document Preparation"
          propHeight="72px"
          propDisplay="flex"
          designedToSaveYouTimeAndReduc="Generate detailed and accurate buyer documents in minutes. Customize terms, conditions, and pricing effortlessly, ensuring every transaction meets your clients’ expectations."
          cardSpacing="/buyer_docs.png"
          login={login}
        />
      </div>
    </section>
  );
};

Products.propTypes = {
  className: PropTypes.string,
};

export default Products;
