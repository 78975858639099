
import PropTypes from "prop-types";
import CalendlyWidget from "../common/calendlyWidget";
import { TextDisplayMedium } from "../common/typography";

const BookDemo = ({ className = "", targetRef }) => {
  return (
    <div
      className={`w-full bg-[#edf4f3] py-20 px-[108px] gap-x[60px] flex flex-row justify-between items-start`}
      ref={targetRef}
    > 
      <div className="w-1/2 mt-16">
        <TextDisplayMedium color={'lightOnSurface'} text={'Book a demo today and unlock'}/> <br/>
        <TextDisplayMedium color={'lightOnSurface'}  text={'faster, smarter real estate'}/> <br/>
        <TextDisplayMedium color={'lightOnSurface'}  text={'workflow'}/>
      </div>
      <div className="w-1/2">
        <CalendlyWidget/>
      </div>
    </div>
  );
};

BookDemo.propTypes = {
  className: PropTypes.string,
};

export default BookDemo;
