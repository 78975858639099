import { Inter } from 'next/font/google'

const inter = Inter({
    subsets: ['latin'],
    display: 'swap',
    variable: '--font-inter',
})

const colorClasses = {
    'lightOnSurface': 'text-lightOnSurface',
    'onSurface':'text-onSurface',
    'lighOnSurfaceVariant':'text-lighOnSurfaceVariant',
    'customColor1': 'text-customColor1',
    'ourlineVarient': 'text-ourlineVarient',
    'lightPrimary':'text-lightPrimary',
    'lightSurfaceContainerHighest': 'text-lightSurfaceContainerHighest',
    'lightOutlineVariant': 'text-lightOutlineVariant',
    'lightSurfaceContainer': 'text-lightSurfaceContainer',
    'lightOutline': 'text-lightOutline',
    'lightOnPrimary': 'text-lightOnPrimarys',
    'lightTertiary': 'text-lightTertiary',
    'errorColor': 'text-errorColor',
    'onSurfaceVariant': 'text-onSurfaceVariant',
    'lightSurfaceVariant': 'text-lightSurfaceVariant',
    'customColor2': 'text-customColor2',
    'customColor3': 'text-customColor3',
    'customColor4': 'text-customColor4s',
    'errorColorRed': 'text-errorColorRed',
    'lightError': 'text-lightErrors',
    'lightPrimaryContainer': 'text-lightPrimaryContainer',
    'lightOnPrimaryContainer':'text-lightOnPrimaryContainer',
    'primary30': 'text-primary30',
};


export const TextDisplayLarge = ({text, color}) => {
    const textColor = colorClasses[color] || `text-${color}`; // Default to the passed color if not mapped
    return <span className={` text-54px font-bold  ${textColor} font-sans`}>{text}</span>
};

export const TextDisplayMedium = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-40px font-semibold  ${textColor} font-sans`}>{text}</span>
};

export const TextHeadlineLarge = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-3xl leading-10 font-semibold ${textColor} font-sans`}>{text}</span>
}

export const TextHeadlineMedium = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-[28px] leading-9 font-semibold ${textColor} font-sans font-Inter`}>{text}</span>
}

export const TextHeadlineSmall = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-2xl leading-8 font-semibold ${textColor} font-sans`}>{text}</span>
}

export const TextTitleLerge = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-[22px] leading-7 font-medium ${textColor} font-sans`}>{text}</span>
}

export const TextTitleMedium = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-base leading-6 font-medium ${textColor} font-sans`}>{text}</span>
}

export const TextTitleSmall = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-sm leading-5 font-medium ${textColor} font-sans`}>{text}</span>
}

export const TextBodyXLarge = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-5 leading-7 font-normal ${textColor} font-sans`}>{text}</span>
}

export const TextBodyLarge = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-base leading-6 font-normal ${textColor} font-sans`}>{text}</span>
}

export const TextBodyMedium = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-sm leading-5 font-normal ${textColor} font-sans`}>{text}</span>
}

export const TextBodySmall = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-xs leading-5 font-normal ${textColor} font-sans`}>{text}</span>
}

export const TextLabelLarge = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-sm leading-5 font-medium ${textColor} font-sans`}>{text}</span>
}

export const TextLabelLargeProminent = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-sm leading-5 font-semibold ${textColor} font-sans`}>{text}</span>
}
export const TextLabelSmall = ({text, color}) => {
     const textColor = colorClasses[color] || color; 
    return <span className={` text-xs leading-4 font-medium ${textColor} font-sans`}>{text}</span>
}