
const Loader = ({loadingMessage}) => {

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
          <div className="flex flex-col items-center justify-center">
            <svg className="animate-spin h-20 w-20 text-green" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
            </svg>
            <p className="font-bold mt-4 text-lg text-white">{loadingMessage}</p>
          </div>
        </div>
    )
}


export default Loader