import { useRef } from "react";
import { Button } from "@mui/material";
import Header from "./header";
import SectionTitle from "./sectionTitle";
import Products from "./products";
import UserReviews from "./userReviews";
import Benefits from "./benefits";
import BookDemo from "./bookDemo";
import Footer from "./footer";
import { TextDisplayLarge, TextLabelLarge } from "../common/typography";

const Desktop = ({login}) => {

  const targetRef = useRef(null);  // Create a ref object

  const scrollToTarget = () => {
    // Scroll to the ref's current element
      targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };


  return (
    <div className="w-full relative bg-[#fff] overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <Header login={login}/>
      <SectionTitle login={login} scrollToTarget={scrollToTarget} />
      <Products login={login} />
      <section className="self-stretch bg-[#fff] overflow-hidden flex flex-col items-center justify-start py-[80px] px-[20px] box-border gap-[60px] max-w-full text-center text-40px text-lightOnSurface font-sans mq750:gap-[30px] mq750:pt-[52px] mq750:pb-[52px] mq750:box-border">
        <h1 className="m-[0px] w-[840px] relative text-inherit leading-[52px] font-semibold font-[inherit] flex items-center justify-center max-w-full mq450:text-[24px] mq450:leading-[31px] mq1050:text-[32px] mq1050:leading-[42px]">
          Go from contract to close faster and smarter with ReBillion
        </h1>
        <div className="w-[1224px] flex flex-row flex-wrap items-start justify-between py-[0px] px-[27px] box-border max-w-full gap-[20px] text-[72px] text-lightPrimary mq1050:justify-center">
          <div className="w-[306.3px] flex flex-col items-center justify-start gap-[8px]">
            <b className="relative inline-block min-w-[98px] mq450:text-[43px] mq1050:text-[58px]">
              5X
            </b>
            <div className="self-stretch relative text-[20px] leading-[28px] text-lighOnSurfaceVariant mq450:text-[16px] mq450:leading-[22px]">
              Document creation and summarization
            </div>
          </div>
          <div className="w-[322.3px] flex flex-col items-center justify-start gap-[8px] max-w-full">
            <b className="relative inline-block min-w-[99px] mq450:text-[43px] mq1050:text-[58px]">
              3X
            </b>
            <div className="self-stretch relative text-[20px] leading-[28px] font-medium text-lighOnSurfaceVariant mq450:text-[16px] mq450:leading-[22px]">
              Efficiency boost across transactions
            </div>
          </div>
          <div className="w-[284.5px] flex flex-col items-center justify-start gap-[8px]">
            <b className="relative mq450:text-[43px] mq1050:text-[58px]">40%</b>
            <div className="self-stretch relative text-[20px] leading-[28px] font-medium text-lighOnSurfaceVariant mq450:text-[16px] mq450:leading-[22px]">
              Lower costs with automated document handling
            </div>
          </div>
        </div>
      </section>
      <UserReviews />
      <Benefits />
      <BookDemo targetRef={targetRef}/>
      <div className="py-20 bg-[url('/gradient-14-1.svg')] bg-center bg-no-repeat bg-cover w-full flex flex-col items-center justify-center">
          <div className="mb-16 text-center">
             <TextDisplayLarge color='lightPrimary' text='Let’s transform your real estate '/> <br/>
             <TextDisplayLarge color='lightPrimary' text='workflow'/>
          </div>
          <button className="bg-lightPrimary py-3 px-6 rounded-md text-lightOnPrimary" onClick={login}>
            <TextLabelLarge color='lightOnPrimary' text='Get started' />
          </button>
      </div>
      <Footer />
    </div>
  );
};

export default Desktop;