import { useEffect } from 'react';

const CalendlyWidget = () => {
    useEffect(() => {
        // Load the Calendly script
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup the script when the component unmounts
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div 
            className="calendly-inline-widget min-w-[320px] h-[35em]" 
            data-url="https://calendly.com/vikas-0dq/30min" 
            // style={{ minWidth: '320px', height: '1000px' }}
        />
    );
};

export default CalendlyWidget;
