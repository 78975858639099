import { Button } from "@mui/material";
import PropTypes from "prop-types";

const Button2 = ({ className = "" }) => {
  return (
    <Button
      className={`self-stretch flex-1 ${className} bg-lightOnPrimary`}
      disableElevation
      variant="contained"
      sx={{
        textTransform: "none",
        color: "#002114",
        fontSize: "14",
        background: "#f1f8f5",
        borderRadius: "6px",
        "&:hover": { background: "#f1f8f5" },
      }}
      onClick={()=>window.open('https://rebillion.ai/contact/')}
    >
      Book demo
    </Button>
  );
};

Button2.propTypes = {
  className: PropTypes.string,
};

export default Button2;
