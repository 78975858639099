import PropTypes from "prop-types";

const Footer = ({ className = "" }) => {
  return (
    <footer
      className={`self-stretch bg-primary30 overflow-hidden flex flex-row items-center justify-center py-[40px] px-[20px] box-border max-w-full text-left text-[14px] text-[#fff] font-sans mq750:gap-[37px] mq450:gap-[19px] ${className}`}
    >
      <div className="w-[1224px] flex flex-row items-start justify-between max-w-full gap-[20px] mq1050:flex-wrap mq1050:justify-center">
        <div className="w-[392px] flex flex-col items-start justify-start gap-[12px] max-w-full text-[20px]">
          <div onClick={()=>window.open('https://rebillion.ai/')} className="flex flex-row items-center justify-start cursor-pointer">
            <img
              className="h-[28px] w-[28px] relative overflow-hidden shrink-0 object-cover min-h-[28px]"
              alt=""
              src="/frame-1@2x.png"
            />
            <div className="flex flex-col items-start justify-start py-[0px] pl-[4px] pr-[0px]">
              <div className="relative leading-[28px] font-medium inline-block min-w-[82px] mq450:text-[16px] mq450:leading-[22px]">
              ReBillion
              </div>
            </div>
          </div>
          <div className="self-stretch relative text-[14px] leading-[20px] text-lightPrimaryContainer">{`ReBillion.ai redefines transaction coordination with AI technology and expert oversight. Complaint, hassle-free, on-time & cost-effective transactions guaranteed.`}</div>
        </div>
        <div className="w-[110px] flex flex-col items-start justify-start gap-[12px]">
          <div className="self-stretch relative leading-[20px] font-semibold">
            Useful links
          </div>
          <div className="flex flex-col items-start justify-center gap-[8px] text-center text-lightPrimaryContainer">
            <div onClick={()=>window.open('https://rebillion.ai/')} className="rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
              <div className="flex flex-row items-center justify-center">
                <a className="[text-decoration:none] relative leading-[20px] text-[inherit] inline-block min-w-[40px]">
                  Home
                </a>
              </div>
            </div>
            <div onClick={()=>window.open('https://rebillion.ai/terms-of-service/')} className="rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
              <div className="flex flex-row items-center justify-center">
                <div className="relative leading-[20px] inline-block min-w-[110px] whitespace-nowrap">
                  Terms of service
                </div>
              </div>
            </div>
            <div onClick={()=>window.open('https://rebillion.ai/privacy-policy/')} className="rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
              <div className="flex flex-row items-center justify-center">
                <a className="[text-decoration:none] relative leading-[20px] text-[inherit] inline-block min-w-[93px]">
                  Privacy policy
                </a>
              </div>
            </div>
            <div onClick={()=>window.open('https://rebillion.ai/contact/')} className="rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
              <div className="flex flex-row items-center justify-center">
                <div className="w-[53px] relative leading-[20px] flex items-center justify-center min-w-[53px]">
                  Contact
                </div>
              </div>
            </div>
            <div onClick={()=>window.open('https://rebillion.ai/services/')} className="rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
              <div className="flex flex-row items-center justify-center">
                <a className="[text-decoration:none] relative leading-[20px] text-[inherit] inline-block min-w-[58px]">
                  Services
                </a>
              </div>
            </div>
            <div onClick={()=>window.open('https://rebillion.ai/subscribe-mailer-ai-tools-for-real-estate-agents-transaction-coordinators-realtors-amp-real-estate-brokers/')} className="rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
              <div className="flex flex-row items-center justify-center">
                <div className="relative leading-[20px] inline-block min-w-[71px]">
                  Mailing list
                </div>
              </div>
            </div>
            <div onClick={()=>window.open('https://rebillion.ai/blog/')} className="rounded-[6px] overflow-hidden flex flex-col items-center justify-center cursor-pointer">
              <div className="flex flex-row items-center justify-center">
                <div className="relative leading-[20px] inline-block min-w-[30px]">
                  Blog
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[314px] flex flex-col items-start justify-start gap-[12px]">
          <div className="self-stretch relative leading-[20px] font-semibold">
            Contact Us
          </div>
          <div className="relative leading-[20px] text-lightPrimaryContainer">
            <p className="m-[0px]">
              Back Office: CoSpaze Coworking, BVR Towers, Veerannapalya.
              Bengaluru 560045. India
            </p>
            <p className="m-[0px]">Tc@rebillion.ai</p>
            <p className="m-[0px]">+16502007853</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
